import React, { useState, useEffect } from 'react';
import { useLocation } from '@reach/router';
import { deleteWedding, getSubEvents, getWeddingDetail } from '../../actions/weddingAction';
import LoadingSpinner from '../loader';
import { toast } from 'react-toastify';
import ViewSubEvents from './viewSubEvents';
import EditSubEventModal from './editSubEventModal';
import './weddingStyles.css';
import moment from 'moment';
import DeleteIcon from '@mui/icons-material/Delete'; // Import Close icon
import { navigate } from 'gatsby';

const WeddingDetail = () => {
  const location = useLocation();
  const url = decodeURI(location.search);
  const params = new URLSearchParams(url);
  const weddingId = params.get('weddingId');

  const [isLoading, setLoading] = useState(false);
  const [weddingDet, setWeddingDet] = useState({});
  const [subEvents, setSubEvents] = useState([]);
  const [weddingInvitationVideoUrl, setWeddingInvitationVideoUrl] = useState({});
  const [weddingCardUrls, setWeddingCardUrls] = useState([]);
  const [showEditSubEventModal, setShowEditSubEventModal] = useState(false);
  const [selectedSubEvent, setSelectedSubEvent] = useState(null); // State to store the selected subEvent

  const getSubEventsFunction = async () => {
    const subEventsResponse = await getSubEvents({ weddingId });
    setSubEvents(subEventsResponse?.subevents);
  };

  const getWedding = async () => {
    try {
      setLoading(true);
      const response = await getWeddingDetail({ weddingId });
      setWeddingDet(response.event);
      setWeddingCardUrls(response.event.wedding_card);
      setWeddingInvitationVideoUrl(response?.event?.invitation_video?.url);
      getSubEventsFunction();
      setLoading(false);
    } catch (error) {
      console.log(error);
      toast.error('Error in getting Wedding Details');
      setLoading(false);
    }
  };

  useEffect(() => {
    getWedding();
  }, []);

  const handleEditSubEvent = (subEvent) => {
    setShowEditSubEventModal(true);
    setSelectedSubEvent(subEvent);
    window.scroll({ top: 0, behavior: 'smooth' });
  };

  const redirectToEdit = (type) => {
    let url;
    if (type === 'weddingCard') {
      url = `/weddings/uploadWeddingCard?weddingId=${weddingId}`;
    } else if (type === 'subEvent') {
      url = `/weddings/createSubEvents?weddingId=${weddingId}`;
    }
    window.open(url, '_blank');
  };
  const handleDeleteWedding = async (event) => {
    event.stopPropagation();
    event.preventDefault();
    const confirmed = window.confirm('Are you sure you want to delete this wedding?');
    if (confirmed) {
      try {
        const result = await deleteWedding(weddingId);
        if (result?.status) {
          toast.success('wedding deleted successfully');
          navigate('/weddings/manageWedding');
        }
      } catch (error) {
        toast.error('failed to delete wedding');
        console.log('failed to delete wedding', error);
      }
    }
  };

  return (
    <>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <>
          {showEditSubEventModal && (
            <EditSubEventModal
              subEvent={selectedSubEvent}
              onClose={() => {
                setShowEditSubEventModal(false);
                getSubEventsFunction();
              }}
            />
          )}
          <div style={{ position: 'relative' }}>
            <h1>{`Wedding Detail for ID: ${weddingId}`}</h1>
            <DeleteIcon
              onClick={handleDeleteWedding}
              style={{ position: 'absolute', cursor: 'pointer', top: '8px', right: '8px' }} // Styling for close icon
            />
            <p>
              Groom:
              <a
                href={`/family-tree?idOfUser=${weddingDet?.metadata?.groom_node?.uid}`}
                target="_blank"
                rel="noopener noreferrer"
                style={{ textDecoration: 'none', padding: 5, color: 'inherit' }}
              >
                {weddingDet?.metadata?.groom_node?.name || 'N/A'}
              </a>
            </p>
            <p>
              Bride:
              <a
                href={`/family-tree?idOfUser=${weddingDet?.metadata?.bride_node?.uid}`}
                target="_blank"
                rel="noopener noreferrer"
                style={{ textDecoration: 'none', padding: 5, color: 'inherit' }}
              >
                {weddingDet?.metadata?.bride_node?.name || 'N/A'}
              </a>
            </p>
            <p>{`Wedding Date: ${moment(weddingDet?.start_date).format('DD-MM-YYYY') || 'N/A'}`}</p>

            <div className="section-container">
              <h2>Wedding Card Photos:</h2>
              <button className="edit-button" onClick={() => redirectToEdit('weddingCard')}>
                Edit
              </button>
            </div>
            {weddingCardUrls && weddingCardUrls.length > 0 ? (
              <div className="wedding-card-container" style={{ display: 'flex', gap: '5px', marginBottom: '' }}>
                {weddingCardUrls.map((photo, index) => (
                  <a key={index} href={photo.url} target="_blank" rel="noopener noreferrer">
                    <div style={{ width: '150px', height: '150px', overflow: 'hidden', border: '1px solid #ccc' }}>
                      <img
                        src={photo.url}
                        alt={`Wedding Card ${index + 1}`}
                        style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                      />
                    </div>
                  </a>
                ))}
              </div>
            ) : (
              <p>Wedding card photos not available</p>
            )}
            <div className="section-container">
              <h2>Wedding Invitation Video:</h2>
            </div>
            {weddingInvitationVideoUrl ? (
              <div className="wedding-invitation-video-container" style={{ display: 'flex', gap: '5px' }}>
                <a href={weddingInvitationVideoUrl} target="_blank" rel="noopener noreferrer">
                  <video
                    controls
                    style={{
                      width: '100%',
                      maxWidth: '500px',
                      borderRadius: '8px',
                      border: '1px solid #ccc',
                      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                    }}
                  >
                    <source src={weddingInvitationVideoUrl} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                </a>
              </div>
            ) : (
              <p>Wedding invitation video not available</p>
            )}

            <div className="section-container" style={{ marginTop: '20px' }}>
              <h2>Subevents:</h2>
              <button className="edit-button" onClick={() => redirectToEdit('subEvent')}>
                Edit
              </button>
            </div>
            {subEvents.length > 0 ? (
              <ViewSubEvents
                subEvents={subEvents}
                onPostDeleteSubEvent={() => getSubEventsFunction()}
                onEditSubEvent={handleEditSubEvent}
                uploader={true}
              />
            ) : (
              <p>No subevents are available</p>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default WeddingDetail;
